.signupFormContainer {
  margin: 0 auto;
  padding: theme("spacing.5") theme("spacing.6");
  background-color: var(--color-page-background);
}

.signupForm {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
}
