.header {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: var(--headerToolbarHeight);
  background-color: var(--color-black);
  border-bottom: 1px solid var(--color-white-30);
}

.header[data-sticky] {
  position: sticky;
  top: 0;
  z-index: 2;
}

.logo {
  padding: theme("spacing.4");
}

.logo [data-logo-wordmark] {
  display: none;
}

.logo [data-logo-smuggy] {
  /*
  Smuggy in the logo SVG sits slightly higher than the wordmark. When displayed
  on its own adjust it a few px lower.
  */
  position: relative;
  top: theme("spacing[0.5]");
}

.logoLink {
  transition: opacity theme("transitionDuration.default");
}

.logoLink:focus-visible {
  outline: none;
  box-shadow: theme("boxShadow.focus") inset;
}

.uploadButton {
  display: none;
}

@media (any-hover: hover) {
  .logoLink:hover {
    opacity: 0.75;
  }
}

@screen medium {
  .logo {
    padding-right: theme("spacing.6");
    padding-left: theme("spacing.6");
  }

  .logo [data-logo-wordmark] {
    display: block;
  }

  .logo [data-logo-smuggy] {
    top: 0;
  }

  .uploadButton {
    display: inline-flex;
  }
}

/*
The upload button has custom responsive handling which shrinks the button to an
"icon only" button on viewports where space is limited.
*/
@media screen and (min-width: theme("screens.medium")) and (width <= 1100px) {
  .header .uploadButton {
    min-width: theme("spacing.10");
    padding: 0;
  }

  .header .uploadButton svg {
    margin: 0;
  }

  .uploadButtonLabel {
    @apply sr-only;
  }
}
