.signupPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-gray-carbon);
}

.photosIcon {
  flex-shrink: 0;
  width: theme("spacing.8");
  height: theme("spacing.8");
  margin-right: theme("spacing.3");
}

.mobileFooter {
  @apply text-sm;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  padding: theme("spacing.5") theme("spacing.6");
  color: var(--color-text-light);
}

.findPhotosMessage {
  @apply text-sm;
  display: none;
  align-items: center;
  margin-right: theme("spacing.6");
  margin-left: auto;
  color: var(--color-text-light);
}

@screen small {
  .mobileFooter {
    display: none;
  }

  .findPhotosMessage {
    display: flex;
  }
}

@screen medium {
  .container {
    margin: 0 auto;
    padding-top: theme("spacing.9");
    padding-bottom: theme("spacing.9");
  }
}

@screen mediumOnly {
  .signupPage {
    background: url("/img/getting-started/questionnaire/questionnaire-1500.jpg") 13% center / cover no-repeat;
    box-shadow: none;
  }
}

@screen large {
  .signupPage {
    background: url("/img/getting-started/questionnaire/questionnaire-3000.jpg") 20% 80% / cover no-repeat;
    box-shadow: none;
  }
}

/**
 * Experiment specific CSS
*/

.container {
  display: flex;
  flex-direction: row;
}

.container :first-child {
  flex-shrink: 0;
}

.experimentalBlurb {
  display: none;
}

@screen large {
  .experimentalBlurb {
    display: block;
    max-width: 400px;
    height: 100%;
    margin: auto;
    margin-right: theme("spacing.8");
    margin-left: theme("spacing.20");
    color: var(--color-text-light);
  }
}

@screen extraLarge {
  .experimentalBlurb {
    max-width: 530px;
    margin-left: theme("spacing.60");
  }
}
